@import "src/variable.module.scss";

.inputWithIcon{
    display:flex;
    justify-content:center;
    align-items:center;
    border:1px solid #002a59;
    border-radius:100px;
}

.inputWithIcon input{
    border:none;
    width:100%;
}

.inputWithIcon input:focus {
    outline: none;
}

.inputWithIcon img{
    margin-right:20px;
}

.homeSearchContainer {
    padding: 12px 24px;
    background-color: #E6EAF1;
}

.homeSlider {
    width: 100%;
    height:100%;
    max-height: 700px;
    object-fit: cover
}
.whyRow {
    display: flex;
    align-items: center;
    margin-top: 52px;
}

.whyRow:first-of-type {
    margin-top:0px;
}

    .whyRow h4 {
    }

    .whyRow img {
        margin-right: 16px;
        height: 40px;
        width: 40px;
    }



    .whyImage {
        width: 100%;
        padding: 20px;
        object-fit: contain;
        border-radius: 40px;
    }

    .serviceSection {
        background-image: url('../../img/home-service-bg.png');
        background-repeat: no-repeat;
        background-position: center left;
        background-size: contain;
    }

    .serviceSection h2:first-child {
        margin-left: 60px;
    }

    .serviceCard {
        margin: auto;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .serviceCardTop {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
       min-height:84px;
    }

    .serviceCardTop img {
        height: 50px;
        margin-right: 16px;
    }

    .serviceCardTop h3 {
        //margin-bottom: 0px;
    }

    .serviceCard button {
        margin-top: auto;
        align-self: flex-start
    }

    .sectionBrands button {
        margin: auto;
    }

    .brandsContainer {
        display: flex;
        justify-content: space-between;
        padding: 0px;
    }

    .brandsContainer img {
        height: 65px;
    }



    .porscheSection {
        background-size: cover;
        margin-bottom:0px;
    }


    .porscheSection p, .porscheSection h2 {
        text-align: center;
        color: #E6EAF1
    }

    .porscheSection button {
    }

    .porscheSection .react-multi-carousel-dot-list {
        background: red;
    }

    .searchContainer {
        margin-top: -115px;
        margin-bottom: 40px;
    }

    .searchButtonContainer {
        text-align: center;
        margin-bottom: -1px
    }

    .searchButton {
        cursor: pointer;
        background-color: #004899;
        border-radius: 100px 100px 0px 0px;
        padding: 8px 32px;
        justify-content: center;
        align-items: center;
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 160% */
    }

    input[type="radio"]:checked + .searchButton {
        border-radius: 100px 100px 0px 0px;
        background: #E6EAF1;
        color: #002A59;
    }

    input[type="radio"] + .searchButton {
        color: #E3F2FC;
    }

    .inputContainer {
        border-radius: 50px;
        background: #E6EAF1;
        box-shadow: -10px 10px 50px 0px rgba(0, 0, 0, 0.25);
        padding: 24px;
        width: fit-content;
        margin: auto;
    }

    .inputContainer select {
        min-width: 240px;
    }


.homeCardContainer {
    padding: 8px;
    max-width: 400px !important;
    margin:auto;
}
.homeCard {
    margin:auto;
    width: 100%;
    max-width:400px!important;
    background-size: contain;
    background-repeat: no-repeat;
    aspect-ratio: 1/1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.homeCardIcon{
    margin-bottom:8px;
    width:64px;
}
.homeCardSubtitle{
    font-size:15px;
    font-weight:bold;
    color:white;
    line-height:24px;
}
.homeCardTitle {
    color: #BECADD;
    font-size: 20px;
    font-weight: 500;
    color: #BECADD;
    line-height: 24px;
    margin-bottom:16px;
}

.logoContainer{
    display:flex;
    justify-content:center;
    align-items:center;
}

.logoContainer div{
    width:auto;
}

.logoContainer img{
    margin:0px 50px;
}


.assistanceCardContainer{
    display:flex;
    justify-content:space-around;
}

.assistanceCard {
    display:flex;
    flex-direction:column;
}

.assistanceCardImg{
    margin-bottom:16px;
}

.assistanceCardBottomRow {
    display: flex;
    margin-bottom:24px;
}
.assistanceCardBottomRow img {
    width:64px;
    margin-right:16px;
}

.assistanceCardText {
    margin-right:32px;
}
.assistanceCardText h3 {
    font-size: 15px;
    font-weight: bold;
    color: #002A59;
    line-height:24px;
}
.assistanceCardText h4 {
    font-size: 24px;
    font-weight: 700;
    color: #004DA6;
    line-height: 24px;
}


.ratingContainer {
    display:flex;
    justify-content:center;
}
.ratingExternalCard {
    margin:24px 40px;
    display:flex;
    flex-direction:column;
    align-items:center;
}
.ratingContainer h4 {
    font-size: 24px;
    font-weight: 700;
    color: #004DA6;
    line-height: 24px;
    margin-bottom:16px;
    text-align:center;
}
.ratingCard {
    border-radius:40px;
    border:12px solid yellow;
    background-color:white;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    width:300px;
}
.rate {
    margin-top: 68px;
    font-size: 96px;
    font-weight: bold;
    color: black;
    line-height: 75px;
    margin-bottom: 24px;
}
.stars {
    color:black;
    font-size:32px;
}
.ratingText {
    font-size: 16px;
    font-weight: 400;
    color: black;
    line-height: 24px;
}
.ratingCard img {
    width:86px;
    margin-top:40px;
    margin-bottom:24px;
}

@media(max-width: $desktop-min-width) {
    .serviceSection h2:first-child {
        margin-left: 0px;
    }

    .searchContainer {
        display: none;
    }

    .serviceSection {
        background-image: unset;
    }

    .brandsContainer {
        justify-content: center
    }

    .brandsContainer img {
        margin: 0px 30px
    }

    .serviceCardTop {
        justify-content: center;
    }

    .serviceCard button {
        margin: auto
    }

    .whyRow h4 {
        font-size: 15px;
        font-weight: bold;
        margin-right: 0px !important;
    }

    .whyRow {
        margin-left: 0px !important;
        margin-top: 25px
    }

    .logoContainer {
        justify-content: space-around;
    }

    .logoContainer div {
        padding: 15px
    }

    .logoContainer img {
        width: 100%;
        margin: 0px;
    }


    .assistanceCardContainer {
        flex-direction: column;
        padding: 24px;
    }


    .assistanceCardImg {
        margin-bottom: 16px;
    }

    .assistanceCardBottomRow {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .assistanceCardBottomRow img {
        margin-right: 0px;
        margin-bottom: 8px;
    }

    .assistanceCardText {
        margin-right: 0px;
    }

    .assistanceCardText h3 {
        text-align: center;
    }

    .assistanceCardText h4 {
        text-align: center;
        margin-bottom: 16px;
    }

    .assistanceCardBottomRow {
        margin-bottom: 60px;
    }



    .ratingContainer {
    flex-direction:column;

    }
    .ratingContainer h4 {
        color:#002a59;
        font-size:22px;
        font-weight:700
    }
}

    @media(max-width: $tablet-min-width) {

        .whyRow img {
        }

        .whyImage {
        }
    }