@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url(./fonts/Montserrat-VariableFont_wght.ttf) format('truetype');
}


.HomeCarousel .react-multi-carousel-dot-list {
    margin-bottom:5px;
}
.loader {
    width: 48px;
    height: 48px;
    border: 5px solid;
    border-color: #002A59 transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin: auto;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
} 
.g-map-bg {
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center center
}
:root {
    --main-bg-color: #E6EAF1;
}

#root {
    background-color: #E6EAF1;
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: 'Montserrat'!important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

section {
    position: relative;
    padding: 20px 50px;
    background-color: #E6EAF1;
    margin-bottom:70px;
}

section h2:first-child{
    margin-bottom:64px;
}
divider1 {
    margin-bottom: 64px;
    display:block;
}

divider2 {
    margin-bottom: 16px;
    display:block;
}

.unstyleLink {
color:inherit;
text-decoration:none;
cursor:pointer;
}
.anchor {
width:100px;
position:absolute;
margin-top:-140px;
}

@media(max-width: 1024px) {
    .anchor {
        margin-top:-100px;
    }
}
    blue {
        color: #004DA6;
    }

    h1 {
        font-size: 63px;
        font-weight: bold;
        line-height: 75px;
        color: #002A59;
        margin-bottom: 0px;
    }

    h2 {
        font-size: 39px;
        font-weight: 700;
        line-height: 50px;
        color: #002A59;
        margin-bottom: 0px;
    }

    h3 {
        font-size: 15px;
        font-family: Montserrat;
        font-weight: 600;
        line-height: 24px;
        color: #002A59;
        margin-bottom: 0px;
    }

    h4:not(servizi_home) {
        color: #002A59;
        /* H4 */
        font-size: 24px;
        font-family: Montserrat;
        font-weight: 600;
        margin-bottom: 0px;
    }

    h4.servizi_home {
        color: #002A59;
        /* H4 */
        font-size: 20px;
        font-family: Montserrat;
        font-weight: 600;
        margin-bottom: 0px;
    }

    p {
        color: #002A59;
        /* Text */
        font-size: 15px;
        font-family: Montserrat;
        line-height: 24px;
    }

    .cta-big {
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
    }

    .cta-medium {
        font-size: 15px;
        font-weight: 700;
        line-height: 15px;
    }

    .cta-small {
        font-size: 9px;
        font-weight: 700;
        line-height: 9px;
    }

    .cta-menu {
        font-size: 15px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: #002A59
    }


    /*SLIDER*/
    .car-slider {
        margin: 0px 60px;
    }

    .react-multi-carousel-dot-list {
        margin-bottom: -25px;
    }

        .react-multi-carousel-dot-list button {
            background: none;
            border-color: #6C86AE;
        }

    .react-multi-carousel-dot--active button {
        background-color: #002A59;
        border-color: #002A59;
    }

    .react-multi-carousel-item {
    }

    .react-multiple-carousel__arrow {
        background-color: #E3F2FC80;
    }

    .react-multiple-carousel__arrow--right::before, .react-multiple-carousel__arrow--left::before {
        color: #002A59;
        font-weight: bold;
    }


    @media(max-width: 1024px) {
        .sliderCenterMode {
            overflow: visible;
        }

            .sliderCenterMode li {
            }

            .sliderCenterMode .item-center div {
                max-width: 350px;
            }

            .sliderCenterMode .item-center img[src*="gruppo"] {
                /*height: 240px;*/
            }

            .sliderCenterMode .react-multi-carousel-item--active::before {
            }
    }

    /*END SLIDER*/
    .primary-color {
        color: #004DA6;
    }


    input, select, textarea {
        border-radius: 100px;
        border: 1px solid #002A59;
        background: #E6EAF1;
        /* Text */
        font-size: 15px;
        font-family: Montserrat;
        line-height: 24px;
        padding: 16px;
    }

    select {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url('./img/arrow-down-select.png');
        background-repeat: no-repeat;
        background-position: 95% center;
    }

        select:required:invalid {
            color: #738AA5;
        }

    option {
        color: black;
    }


    textarea {
        border-radius: 15px;
    }

    input[type=checkbox] {
        width: auto;
        margin-right: 10px;
        margin-bottom: 0px
    }

    label {
        color: #BECADD;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: 0.5px;
    }

    .nav-link {
        font-size: 15px;
        line-height: 24px;
        font-weight: 600;
        color: #002A59;
        text-decoration: none;
        margin-left: 24px;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .dropdown-toggle {
        margin-right: 15px;
    }

        .dropdown-toggle::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: url('./img/arrow-down.png') no-repeat right;
            border: none
        }

    .dropdown-order button {
        color: #002A59 !important;
        /* CTA menu */
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 160% */
        background: transparent !important;
        border: none !important;
    }

        .dropdown-order button b {
            font-weight: 600;
        }

        .dropdown-order button:hover {
            background: none;
            color: #002A59;
        }


    @media(max-width: 1024px) {
        h2 {
            /* Mobile/H2 */
            font-size: 20px;
            font-family: Montserrat;
            font-weight: 700;
            line-height: 22px
        }

        .car-slider {
            margin: 0
        }

        section:not(sections_home) {
            position: relative;
            padding: 20px 0px;
            background-color: #E6EAF1
        }

        section.sections_home {
            padding: 0px;
            margin-bottom: 30px;
            background-color: #E6EAF1
        }
    }
