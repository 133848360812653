@import "src/variable.module.scss";


.container {
    border-radius: 20px;
    background: #002A59;
    overflow:hidden;
}

.contactFormContainer {
    padding: 45px 40px;
    max-width:700px;
    margin:auto;
}

.title {
    color: #F7FBFE;
    font-size: 39px;
    font-weight: 600;
    line-height: 150%;
    text-align:center;
}

.description {
    color: #BECADD;
    /* Text */
    font-size: 15px;
    font-family: Montserrat;
    line-height: 24px;
    margin-bottom:50px;
    text-align:center;
}

.contactsContainer{
    display:flex;
    align-items:center;
    margin-top:10px;
}

.contactsContainerIcon {
    color: #43A4ED;
    /* Text */
    font-size: 25px;
    margin-right:10px;
}

.contactsLabel {
    color: #43A4ED;
    /* Text */
    font-size: 14px;
    font-family: Montserrat;
    line-height: 24px;
    margin-bottom:0px;
}

.contactsValue {
    color: #BECADD;
    /* Text */
    font-size: 14px;
    font-family: Montserrat;
    line-height: 24px;
    margin-bottom: 0px;
}

.container input, .container select, .container textarea {
    width: 100%;
    margin-bottom: 24px;
}

