@import "src/variable.module.scss";


.contactsContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.contactsContainerIcon {
    color: #002A59;
    /* Text */
    font-size: 25px;
    margin-right: 10px;
}

.contactsLabel {
    color: #004DA6;
    /* Text */
    font-family: Montserrat;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom:0px;
}

.contactsValue {
    color: #002A59;
    /* Text */
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0px;
}

.socialIcon{
    margin-right:25px;
    cursor:pointer;
}

.contactFormContainer input, .contactFormContainer select, .contactFormContainer textarea {
    width: 100%;
    margin-bottom: 24px;
    background:white;
}

.contactFormContainer input[type=checkbox] {
    width: auto;
    margin-right: 10px;
    margin-bottom: 0px;
}

.contactFormContainer label {
    color: #002A59;
}

.contactsContainer2 {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.contactsContainerIcon2 {
    color: #002A59;
    /* Text */
    margin-right: 10px;
    width:24px
}

.contactsValue2 {
    color: #002A59;
    /* Text */
    font-size: 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom:0px
}

.enlargedIcon {
    font-size: 20px;
}

.pageTitle {
    margin-top: 68px;
}

.content {
    padding-top: 64px;
}
.contentContainer {
    padding-left: 64px;
    padding-right: 64px;
}
.locationCard {
    background-color:white;
    border-radius:30px;
    padding:32px;
    display:flex;
    margin-bottom:30px;
}
.locationCardImageContainer {
    margin-right:30px;
}
.locationCardTextContainer {
}
.locationCardTitle {
    font-size: 15px;
    line-height:24px;
    font-weight: bold;
    color: #002A59;
}
.locationCardTitle2 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: #002A59;
}
.locationCardTitle3 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    color: #990047;
    margin-bottom:8px;
}
.locationCardHours {
    font-size: 15px;
    line-height: 24px;
    font-weight: bold;
    color: #002A59;
}
.locationCardHoursText {
    font-size: 15px;
    line-height: 24px;
    color: #002A59;
    margin-bottom:16px;
}
.locationCardLocation {
    display: flex;
    align-items:center;
}
.locationCardLocation img {
    width: 32px;
    height: 32px;
    margin-right:16px;
}
.locationCardLocation div {
    font-size: 15px;
    line-height: 24px;
    color: #002A59;
}

.mobilePopupMenu{

}

.closeButton{
    display:none;
}

@media(max-width: $desktop-min-width) {
    .closeButton{
       display:block;
    }
    .pageTitle {
        margin-top: 42px;
        font-size: 26px;
        line-height: 34px;
    }

    .contentContainer {
        padding-left: 24px;
        padding-right: 24px;
    }

    .content {
        padding-top: 38px;
    }

    .locationCard {
        flex-direction: column;
        padding: 26px;
    }

    .locationCardImageContainer img {
        width: 100%;
        margin-right: 0px;
    }

    .locationCardImageContainer {
        margin-right: 0px;
        margin-bottom: 16px;
    }

    .mobilePopupMenu {
        position: fixed;
        right: 0px;
        top: 0px;
        bottom: 0px;
        overflow:scroll;
        background-color: #002A59;
        z-index:100000000000;
        color:white;
        padding:24px;
    }
    .mobilePopupMenu h2, .mobilePopupMenu p, .mobilePopupMenu a {
        color: white;
        text-align: center;
    }

    .mobilePopupMenu label{
        color:white;
    }

    .closeButton{
        text-align:right;
        font-size:30px;
        margin-bottom:24px;
    }
}