@import "src/variable.module.scss";

.filterContainer {
    border-radius: 40px;
    background: #E6EAF1;
    box-shadow: -10px 10px 50px 0px rgba(0, 0, 0, 0.25);
    padding:70px 30px;
}

.filterContainer h4{
    margin-top:25px;
    margin-bottom:5px;
}

.paginationContainer{
    text-align:center;
    display:flex;
    justify-content:center;
    align-items:center;
}
.paginationPrev, .paginationNext {
    border: none;
    border-radius: 50%;
    background: none;
    margin: 0px 10px;
    font-size: 20px;
    color: #002A59;
}

.paginationPageActive, .paginationPage {
    color: #002A59;
    background: none;
    border: none;
    text-align: center;
    /* H4 */
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
    margin: 0px 2px;
    padding:8px;
}
.paginationPageActive {
    color: #BECADD;
}


.filterMobileHeaderContainer{
    display:flex;
    flex:1;
    align-items:center;
    justify-content:space-between
}
.filterMobileHeaderContainer img{
    height:25px;
}

.filterMobileHeaderIconContainer {
    display: flex;
    align-items:flex-end;
}

.filterMobileHeaderIconContainer img{
    margin-right:15px;
    height:22px;
    cursor:pointer;
}

.filterMobileTop {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between
}
.filterMobileTopLabel {
    color: #002A59;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px; /* 277.778% */
}

.filterMobileTopRemove button{
    color: #002A59;
    font-family: Montserrat;
    font-size: 12.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 192% */
    background:none;
    border:none;
}

.topbarFilterMobile{
    display:flex;
    align-items:center;
    margin-bottom:15px;
}

.topbarFilterMobile input {
    height: 48px;
    padding: 10px;
    margin-right:20px;
}

.mobileFilterButton img{
 width:8px;
 height:8px;
 margin-left:10px;
}
.mobileFilterButton {
    border-radius: 5px;
    border: 1px solid #002A59;
    background: none;
    color: #002A59;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 160% */
    margin-right:10px;
    margin-bottom:10px;
}


@media(max-width: $desktop-min-width) {
    .filterContainer {
        background: unset;
        box-shadow: unset;
        padding: 10px;
    }

    .filterContainer h4 {
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 140% */
    }

    .filterContainer input, .filterContainer select {
        height: 48px;
        padding: 10px;
    }

    .filterContainer input[type=checkbox] {
        vertical-align: middle
    }
}