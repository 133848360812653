@import "src/variable.module.scss";

.mainContainer {
    width: 100%;
    color: $secondary_text;
    background-repeat: no-repeat;
    background-position: right;
    background-size:cover;
    min-height: 540px;
    display: flex;
    flex-direction: column;
}

.mainContainerMini {
    width: 100%;
    color: $secondary_text;
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    min-height: 330px;
    display: flex;
    flex-direction: column;
}

.contentContainer .container{
    background:red;
}

.contentContainer {
    background: linear-gradient(90deg, #002A59 30.27%, rgba(0, 0, 0, 0) 60.77%);
    padding:50px 0px;
    flex:1;
    display:flex;
    flex-direction:column;
    justify-content:center;
}

.title {
    font-weight: 700;
    font-size: 63px;
    line-height: 75px;
    white-space:pre;
}

.subtitle {
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    margin-top:24px;
}

.subtitleNoLH {
    font-weight: 400;
    font-size: 24px;
    margin-top:24px;
}

.actionContainer{
    margin-top:52px;
}

.breadcrumbContainer {
    color: #002A59;
    text-align: right;
    margin-top: 16px;
}

.breadcrumbContainer a {
    color: #002A59;
    text-align: right;
    /* Text */
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 160% */
    margin-left: 3px;
    text-decoration: none;
}

.breadcrumbContainer a:hover{
    text-decoration:underline;
}

.breadcrumbContainer div:last-child a {
    color: #990047;
}

@media(max-width: $desktop-min-width) {
    .mainContainer {
        background-position: center;
        min-height: 278px;
    }

    .mainContainer button {
        margin-top: 4rem
    }

    .mainContainerMini {
        background-position: center;
        min-height: 278px;
    }

    .contentContainer {
        background: #002A594D;
        align-items: center;
        text-align: center;
    }

    .actionContainer {
        margin-top: auto;
        display: flex;
        justify-content: center;
    }

    .title {
        font-weight: 700;
        font-size: 26px;
        line-height: 34px;
    }

    .subtitle {
        display: none;
    }

    .breadcrumbContainer {
        text-align: left;
        margin-top: 4px;
        margin-right: 0px !important;
    }
}