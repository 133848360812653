@import "src/variable.module.scss";

.headerContainer {
    background-color: #002A59;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 62px;
    padding-right: 62px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #E6EAF1;
    height: 75px;
    //position: relative;
    position: fixed;
    z-index:2000;
    left:0px;
    right:0px;
}


.headerRight{
    display:flex;
    align-items:center;
}

.menuButton {
    display: flex;
    justify-content:center;
    align-items:center;
    margin-left:40px;
    font-size:15px;
    line-height:24px;
    font-weight:300;
    cursor:pointer;
}

.menuButtonIcon {
    color: #E6EAF1;
    font-size:30px;
    margin-left:16px;
}


.headerMenuMobileContainer {
    display: block;
    position: fixed;
    transition: all 0.5s ease-out;
    background-color: white;
    top: 75px;
    bottom:0px;
    //min-height: calc(100vh - 100%);
    z-index: 100000;
    overflow-y: auto;
    padding: 25px 40px;
    padding-bottom:100px;
    min-width: 375px;
    /* https://smoothshadows.com/#djEsMSw1LDAuMDgsMjQsMzIsMCwjMDMwNzEyLCNmM2Y0ZjYsI2ZmZmZmZiwy */
    box-shadow: 0px 1px 1px rgba(3, 7, 18, 0.02), 0px 5px 4px rgba(3, 7, 18, 0.03), 0px 12px 9px rgba(3, 7, 18, 0.05), 0px 20px 15px rgba(3, 7, 18, 0.06), 0px 32px 24px rgba(3, 7, 18, 0.08);

}

.menuLinkBold {
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #002A59;
    cursor:pointer;
}

.menuLink {
    font-weight: 300;
    font-size: 15px;
    line-height: 34px;
    color: #002A59;
    cursor:pointer;
}

.menuLink img {
    width:32px;
    margin-left:8px;
}

.divider {
    height: 1px;
    background-color: #6C86AE;
    margin: 24px 0px;
    width: calc(100% + 80px); 
    margin-left: -40px;
    opacity:0.4
}






.headerTopRow {
    display: flex;
    padding-left: 62px;
    padding-right: 62px;
    background-color: red
}

.headerTopRowLeft{
    flex:1
}

.headerTopRowRight{

}


.headerBottomRow {
    display: flex;
    align-items: center;
}

.headerBottomRowLeft {
}

.headerBottomRowRight {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content:end
}

.headerBottomLinkContainer {

    text-align: right;
}

.headerLogoContainer{

}

.headerLogo {
    max-width:300px;
    cursor:pointer;
}

.headerTopLink {
    font-size: 15px;
    line-height: 24px;
    font-weight:400;
    color: $text_color;
    text-decoration:none;
    margin-right:40px
}

.headerBottomLink, .nav-link {
}

.dropdownItem {
    //background-color: #0AA;
}

.headerOpenMenuButton{
    color:$text_color;
    font-size:24px;
    cursor:pointer;
    display:none;
}


.headerTopMenuMobile{
    display:none;
}

.infoText {
    color: #004DA6;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    display:flex;
    align-items:center;
}

.infoText img{
    margin-right:10px;
}
.infoText a{
    color:inherit;
    text-decoration:none
}

.socialContainer{
    margin-top:auto;
    display:flex;
    margin-top:20px
}
.socialContainer button{
    margin-right:20px;
    height:50px;
    font-size:20px;
}

@media(max-width: $desktop-min-width) {

    .menuButton {
        margin-left: 0px;
    }
    .menuButton span{
        display:none;
    }



    .headerContainer{
        padding:24px;
    }

    .headerLeft img{
        width:180px;
        height:auto
    }

    .headerBottomLinkContainer .headerBottomLink {
        display: none;
    }

    .headerTopMenuMobile {
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .headerOpenMenuButton {
        display: block;
    }

    .headerTopRow {
        display: none;
    }

    .divider {
        display: none;
    }

    .headerMenuMobile .divider {
        display: block;
    }

    .headerMenuMobileContainer {
        display: block;
        position: fixed;
        transition: all 0.5s ease-out;
        width: 100%;
        height: 100%;
        //min-height: calc(100vh - 100%);
        z-index: 100000;
        overflow-y: auto;
        padding:24px;
        padding-bottom:100px;
    }

    .headerMenuMobile {
        display: flex;
        flex-direction: column;
        padding: 0px 20px
    }

    .headerContainer {
        //padding: 20px;
    }

    .headerBottomLink {
        color: #002A59;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 133.333% */
        display: block;
        padding: 1rem;
        padding-left: 0px;
        text-decoration: none;
    }
    .headerBottomLinkSub {
        color: #002A59;
        font-family: Montserrat;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 160% */
        display: block;
        text-decoration:none;
        margin-top:10px;
    }

    .headerTopLink {
        margin-right: 0px;
        padding: 10px 0px;
    }

    .headerLogo {
        max-width: 200px;
    }

}

.infoTitle {
    color: #002A59;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 120% */
}

.addressTitle {
    color:  #002A59;
    font-feature-settings: 'calt' off;
    font-family: Montserrat;
    font-size: 12.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 11px; /* 88% */
    margin-top:25px
}

.addressValue {
    color: var(--Primary-text, #002A59);
    font-feature-settings: 'calt' off;
    font-family: Montserrat;
    font-size: 12.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 160% */
    margin-top:5px;
    display:flex;
}

.addressValue img{
    margin-top:2px;
    margin-right:5px
}

.addressValue a{
    color:inherit;
    text-decoration:none;
}

.item {
}

.itemBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 1rem;
    padding-left: 0px;
    text-align: left;
    background-color: transparent;
    border: none;
    /* H4 */
    color: #002A59;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    pointer-events:none;

}



.itemContent {
    transition: height 0.25s cubic-bezier(0, 0, 0, 1);
}

.itemPanel {
    padding: 0px;
    margin-top:-15px;
}

.chevron {
    margin-left: auto;
    transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
    padding: 5px;
    border-radius: 50%;
    width: 25px;
}

.itemBtnExpanded .chevron {
    transform: rotate(180deg);
}

.itemBtn img{
    display:none;
}

@media(max-width: $desktop-min-width) {
    .itemBtn {
        pointer-events: initial;
    }
    .itemBtn img {
        display: block
    }
}

    .stickyHeader {
        position: fixed;
        top: 0;
        height: 82px;
        width: 100%;
        background-color: #E6EAF1;
        z-index: 1000;
        padding-right: 60px;
        padding-left: 60px;
        box-shadow: 0px 1px 35px grey;
    }

    .idleHeader {
        height: 60px;
        width: 100%;
        background-color: #E6EAF1;
        z-index: 1000;
        padding-right: 60px;
        padding-left: 60px;
    }
    //