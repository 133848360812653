@import "src/variable.module.scss";

.pageTitle {
    margin-top: 68px;
}

.content {
    padding-top: 64px;
}

.contentContainer {
    padding-left: 64px;
    padding-right: 64px;
}

@media(max-width: $desktop-min-width) {

    .pageTitle {
        margin-top: 42px;
        font-size: 26px;
        line-height: 34px;
    }

    .contentContainer {
        padding-left: 24px;
        padding-right: 24px;
    }

    .content {
        padding-top: 38px;
    }
}