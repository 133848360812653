@import "src/variable.module.scss";

.contentContainer {
    padding-left: 64px;
    padding-right: 64px;
}

.content {
    padding-top: 64px;
}
.image {
    width: 100%;
    max-width:500px;
}
.paragraph {
    line-height: 24px;
    font-size: 15px;
    color: #002a59;
    margin-bottom: 40px;
}

.titleWithIcon {
    display: flex;
    align-items:center;
}

.titleWithIcon img {
    width: 64px;
    height: 64px;
    margin-right: 28px;
}

.titleWithIcon h2 {
    line-height: auto;
    font-size: 39px;
    font-weight: bold;
    color: #004DA6;
    margin-bottom: 24px;
}



.checkmarksContainer {
    display: flex;
    align-items: flex-start;
}

.checkmarksContainer h2 {
    margin-bottom: 0px !important;
    color: #002A59;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    line-height: 48px; /* 200% */
}

.checkmarksContainer img {
    margin-right: 15px;
    margin-top: 8px;
}


.galleryTopRow{
    display:flex;
    justify-content:space-between;
    padding:10px;
}

.galleryContainer {
    position: absolute;
    background: white;
    transition: all 0.5s ease-out;
    max-height:75%;
    overflow-y:scroll;
}

.galleryBackDrop {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    top:0px;
    background: blue;
    z-index: 1001;
    background-color: #00000080;
    pointer-events:none;
    transition: all 0.5s ease-out;
}

.galleryImageContainer{
    display:flex;
    flex-wrap:wrap;
    justify-content:space-around;
    padding:20px;

}
.galleryContainer img {
    width: 200px;
    margin:15px;
    margin-bottom:30px;
}

.contactBackDrop {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    top: 0px;
    background: blue;
    z-index: 1001;
    background-color: #00000080;
    pointer-events: none;
    transition: all 0.5s ease-out;
    overflow-y: scroll;
}
.contactBackDrop section{
    background:none;

}
.vehicleImage {
    width: 100%;
    border-radius: 30px;
    padding: 5px;
    height: 250px;
    object-fit:cover;
}
.vehicleImageBig {
    width: 100%;
    border-radius: 30px;
    padding: 5px;
    height: 500px;
    object-fit:cover;
}

.buttonContainerImage {
    width:100%;
    position:relative;
    margin-top:-30px;
}

.buttonContainerImage button{
    margin:auto;
}

.priceCard {
    background: #002A59;
    box-shadow: -10px 10px 50px 0px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
    padding:40px;
}
.priceText {
    font-family: Montserrat;

    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    color: #E6EAF1;
}
.priceText small {
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #BECADD
}
.prezzo small {
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: #BECADD
}
.prezzo bottom {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    color: #BECADD
}
.priceCard p {
    color: #BECADD;
}

.priceCardButtonContainer{
    display:flex;
}
.priceCardButtonContainer button {
    height: 40px;
}
.priceCard button:last-of-type {
    background: #43A4ED;
    margin-left: 10px;
    height: 40px
}
.priceCard button:first-of-type {
    margin-right:10px;
    flex:1;
}

.attributeContainer {
    border-radius: 40px;
    border: 1px solid #002A59;
    padding: 24px;
    height:100%;
    display:flex;
    align-items:center;
}
.attribute {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.attibuteIcon{

}

.attibuteText{
}

.attributeTitle {
    color: #002A59;
    /* CTA medium */
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.attributeValue {
    color: #002A59;
    /* Text */
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-transform:capitalize
}



.optional {
    display: flex;
    align-items: center;
    gap: 8px;
}

.optionalIcon img {
    width:24px;
    height:24px;
}

.optionalText {
    font-family: Montserrat;
    font-size: 15px;
    font-weight: bold;
    line-height: 24px;
}

.optionalTitle {
    color: #002A59;
    /* CTA medium */
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.optionalValue {
    color: #002A59;
    /* Text */
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
    text-transform: capitalize
}

.contactCard {
    border-radius: 40px;
    background: linear-gradient(0deg, #002A59 0%, #002A59 100%), #E3F2FC;
    box-shadow: -10px 10px 25px 0px rgba(0, 0, 0, 0.25);
    padding:50px;
    text-align:center
}

.contactCard h4, .contactCard p {
    color: #E6EAF1;
    margin-bottom:15px;
    font-size: 18px
}

.contactCardButtonContainer {
    display:flex;
    justify-content:center
}

.contactCardButtonContainer button{
    margin:0px 10px;
    font-size: 14px
}
.contactCardButtonContainer button:first-of-type {
    background: #990047;
    border-color: #990047;
}

.shareContainer{
    font-size:25px;
    display:flex;
}
.shareContainer img{
    height:25px;
}
.shareContainer svg {
    color: #002A59;
    margin-right: 15px;
    cursor: pointer;
}

.imageContainerMobile{
    display:none;
}

@media(max-width: $tablet-min-width) {
    .contentContainer{
        display:none;
    }
    .galleryContainer img {
        width: 44%;
    }

    .contactCardButtonContainer {
        flex-direction: column
    }

    .contactCardButtonContainer button {
        margin: 10px 0px;
    }

    .imageContainer {
        display: none;
    }

    .imageContainerMobile {
        display: block;
        height:200px;
    }

    .imageContainerMobile img{
        border-radius:50px;
        width:100%;
    }
}