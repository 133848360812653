@import "src/variable.module.scss";

.buttonGroup {
    position: absolute;
    top: 50%;
    display: flex;
    width: 100%;
    justify-content: space-between;
    pointer-events: none;
}

.buttonGroupBottomRight {
    position: absolute;
    bottom: -50px;
    right: 0px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.buttonGroupBottomRight .leftArrowContainer{
    margin-right:30px;  
}

.buttonGroupBottomCenter {
    position: absolute;
    bottom: -50px;
    display: flex;
    width: 100%;
    justify-content: center;
}
.buttonGroupBottomCenter .leftArrowContainer {
    margin-right: 55px;
}
.buttonGroupBottomCenter .rightArrowContainer {
    margin-right: 15px;
}


.leftArrowContainer {
}
.leftArrowContainer button, .rightArrowContainer button {
    height: 40px;
    pointer-events:all;
}
.carouselContainer {
    position: relative;
}

.carouselContainerCenter {
    position: relative;
}

@media(max-width: 1024px) {
    .carouselContainerCenter {
        position: relative;
        transform: scale(0.85);
    }

}