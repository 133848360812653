@import "src/variable.module.scss";

.footerContainer {
    background-color: $tertiary_bg;
    color: $tertiary_text;
}

.footerNewsletterContainer {
    background-color: $secondary_bg;
    padding:20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footerNewsletterTitle{
    color: $secondary_text;
    font-size: 24px;
    font-weight: 700;
}

.logo{

}

.footerLink {
    font-weight: 400;
    font-size: 12px;
    line-height: 32px;
    color: $tertiary_text;
    text-decoration:none;
}

.infoTextBig {
    color: #BECADD;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px; /* 141.667% */
}

.infoText {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    margin-top: 15px;
}

.infoTextAddress {
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    margin-top: 15px;
    max-width: 20px;
}

.infoText svg, .infoTextBig svg {
    margin-right: 10px;
}
.divider {
    width: 100%;
    height: 1px;
    background-color: #2F527A;
    margin: 80px 0px 40px 0px;
}

.bottomContainer{
    display: flex;
    align-content:center;
}

.copyrightContainer {
    align-items: center;
    display: flex;
}

.socialContainer {
    margin-left:auto;
    display: flex;
}

.socialContainer button {
    margin-right: 20px;
    height: 50px;
    font-size: 20px;
}

.socialContainerMobile {
    display: none;
}

.footerTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
    color: #E9FAFF;
}

@media(max-width: $desktop-min-width) {
    .footerNewsletterContainer {
        display: block;
        text-align: center;
    }

    .footerNewsletterContainer button, footerNewsletterContainer input {
        display: inline-block;
    }

    .footerNewsletterTitle {
    }

    .socialContainer {
        display: none;
    }

    .socialContainerMobile {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .socialContainerMobile button {
        margin: 0px 10px;
        height: 50px;
        font-size: 20px;
    }

    .divider {
        margin: 20px 0px;
    }

    .copyrightContainer {
        text-align: center
    }

    .footerNewsletterTitle {
        margin-bottom: 15px
    }
}