.title{
    margin-bottom:5px!important;
}
.text {
    color: #002A59;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 160% */
    margin-bottom:30px;
}
