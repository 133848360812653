@import "src/variable.module.scss";



.item {
    margin-top:40px;
    border-bottom: 1px solid #002A59;
}

.itemBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    padding: 1rem;
    padding-left:0px;
    text-align: left;
    background-color: transparent;
    border: none;
    color: #002A59;
    /* H4 */
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.2px;
}



.itemContent {
    transition: height 0.25s cubic-bezier(0, 0, 0, 1);
}

.itemPanel {
    padding: 1.5rem 0px;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #002A59;
}

.chevron {
    margin-left: auto;
    transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
    padding:5px;
    border-radius:50%;
    width:48px;
}

.itemBtnExpanded .chevron {
    transform: rotate(180deg);
}
