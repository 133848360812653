@import "src/variable.module.scss";

.mainContainer {
    border-radius: 30px;
    background: #FFF;
    position: relative;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    cursor:pointer;
    max-width:300px;
    margin:auto
}

.image {
    height: 200px;
    width: 100%;
    pointer-events: none;
    object-fit:cover;
}

.bookmarkContainer {
    position: absolute;
    right: 20px;
    top: 180px;
    background-color: white;
    border-radius: 100%;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .25);
}
.contentContainer {
    padding:32px 24px;
    pointer-events:none;
}

.carTopcontainer{
    display:flex;
    align-items:center;
}
.carTitle {
    color: $text_color;
    font-size: 15px;
    font-family: Montserrat;
    font-weight: 800;
    line-height: 15px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-transform:uppercase;
}

.carAttribute {
    color: #002A59;
    font-size: 15px;
    font-family: Montserrat;
    line-height: 24px;
}
.carAttribute strong {
}

.bottomContainer{
    margin-top:auto;
    display:flex;
    padding:20px;
    padding-top:0px;
    justify-content:space-between;
}

.price {
    color: #002A59;
    font-size: 20px;
    font-family: Montserrat;
    font-weight: bolder;
    line-height: 24px;
    margin-left:8px;
}

.bottomContainer button {
    color: #004899;
    text-align: center;
    /* CTA small */
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 600;
    line-height: 11px;
    padding:8px 16px;
}

.carMiniTitle {
    color: #002A59;
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 500;
    line-height: 11px;
}

@media(max-width: 1024px) {
    .mainContainer {
        max-width: 340px;
        height:430px;
    }
}