@import "src/variable.module.scss";
.all{

}

.primaryButton {
    background: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 40px;
    gap: 8px;
    border: 1px solid $text-color;
    border-radius: 100px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color:$text-color;
}


.primaryButton:hover {
    background: $primary_bg_hover;
    cursor: pointer;
}

.secondaryButton {
    background: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 40px;
    gap: 8px;
    border: 1px solid $secondary_text;
    border-radius: 100px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: $secondary_text;
}

.secondaryNoBorderButton {
    background: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 40px;
    gap: 8px;
    border: 2px solid #43A4ED;
    border-radius: 100px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: $secondary_text;
}

.secondaryButton:hover {
    background: $primary_bg;
    color: $text_color;
    cursor: pointer;
}

.tertiaryButton {
    background: $secondary_bg;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 40px;
    gap: 8px;
    border: 1px solid $secondary_bg;
    border-radius: 100px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: $secondary_text;
}

.tertiaryButton:hover {
    background: $secondary_bg_hover;
    cursor: pointer;
}

.tertiaryMobileButton {
    background: $secondary_bg;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 24px;
    gap: 8px;
    border: 1px solid $secondary_bg;
    border-radius: 100px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: $secondary_text;
}

.tertiaryMobileButton:hover {
    background: $primary_bg;
    color: $text_color;
    cursor: pointer;
}

.Button4 {
    background: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 40px;
    gap: 8px;
    border: 1px solid #004899;
    border-radius: 100px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #004899;
}

.Button4:hover {
    background: $primary_bg_hover;
    cursor: pointer;
}

.Button5 {
    background: #002a59;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 40px;
    gap: 8px;
    border: 1px solid #002a59;
    border-radius: 100px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #fff;
}

.Button5:hover {
    background: #001D3D;
    cursor: pointer;
}

.bookmark {
    background: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 40px;
    gap: 8px;
    border: none;
    border-radius: 100px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    color: #002A59;
}

.bookmark:hover {
    background: #E6EAF1;
    cursor: pointer;
}

.bookmarkSelected {
    background: #002A59;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 40px;
    gap: 8px;
    border: none;
    border-radius: 100px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    color: #ffffff;
}


.iconOnly {
    padding: 5px;
    border-radius: 100%;
    aspect-ratio:1/1;
}


@media(max-width: $desktop-min-width) {
    .all {
        height: 55px;
        font-size: 15px;
    }
}