@import "src/variable.module.scss";

.ratingCard{

}

.ratingCard img{
    margin: 24px 0px;
    width:40px;
}

.ratingtext {
    color: #002A59;
    color: #002A59;
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: italic;
    font-weight: 400;
    line-height: 150%; /* 22.5px */
    letter-spacing: -0.33px;
}

.ratingStarContainer {
    font-size:32px
}

.name{
    margin-top:24px
}