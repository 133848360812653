@import "src/variable.module.scss";

.button {
    border-radius: 40px;
    border: 1px solid #002A59;
    background:transparent;
    min-width:200px;
    aspect-ratio:1;
    margin-bottom:56px
}

.button:hover {
    background: #BECADD
}

.button img{
    width:80px;
    height:80px;
    object-fit:contain;
    margin-bottom:20px;
}

.button h4{
    text-transform:capitalize
}

@media(max-width: $desktop-min-width) {
    .button {
        min-width: 100px;
        aspect-ratio: 1;
        margin-bottom: 25px;
        border-radius:20px;
    }
    .button img {
        width: 50px;
        height: 50px;
    }
    .button h4 {
        font-size:11px
    }

}