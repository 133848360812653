@import "src/variable.module.scss";

.pageTitle{
    margin-top: 68px;
}

.breadcrumb{
    text-align:right;
    margin-top:25px;
    margin-right:0px!important;

}
.contentContainer {
    padding-left: 64px;
    padding-right: 64px;
}

.content {
    padding-top: 64px;
}

.image {
    width:100%;
}

.contentText{
}

.titleWithIcon{
    display:flex;
}

.titleWithIcon img {
    width: 64px;
    height:64px;
    margin-right: 28px;
}
.titleWithIcon h2 {
    line-height: auto;
    font-size: 39px;
    font-weight: bold;
    color: #004DA6;
    margin-bottom: 40px;
}

.paragraph {
    line-height: 24px;
    font-size: 15px;
    color: #002a59;
    margin-bottom: 40px;
}

.miniTitle {
    line-height: auto;
    font-size: 24px;
    font-weight: 700;
    color: #004DA6;
}

.paragraphBold {
    line-height: 24px;
    font-size: 15px;
    color: #002a59;
    margin-bottom: 40px;
    font-weight:bold;
}

.checkmarksContainer {
    display: flex;
    align-items: flex-start;
}

.checkmarksContainer h2 {
    margin-bottom: 0px !important;
    color: #002A59;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: bold;
    line-height: 48px; /* 200% */
}

.checkmarksContainer img {
    margin-right: 15px;
    margin-top:8px;
}

.littleTitle {
    color: #43A4ED;
    font-size:15px;
    line-height:24px;
    font-weight:bold;
    text-transform:uppercase;
}


.serviceContent{
}

.serviceTitleWithImage {
    display: flex;
}

.serviceTitleWithImage img{
    width:64px;
    height:64px;
    margin-right:16px;
    margin-top:10px;
}

.serviceTitleWithImage h2{
    font-size:39px;
    font-weight:bold;
}

.serviceContent p{
    margin-top:24px;
    color:#002a59;
    font-size:15px;
    margin-bottom:40px;
}

.brandSection h2{
    margin-top:100px;
    text-align:center;
}

.brandContainer {
    display:flex;
    justify-content:space-around;
    flex-wrap:wrap;
}
.brandContainer img{
    margin-top:64px
}

@media(max-width: $desktop-min-width) {

    .contentContainer {
        padding-left: 24px;
        padding-right: 24px;
    }

    .breadcrumb {
        text-align: left;
        margin-top: 4px;
        margin-right: 0px !important;
    }

    .pageTitle {
        margin-top: 42px;
        font-size: 26px;
        line-height: 34px;
    }


    .content {
        padding-top: 38px;
    }

    .image {
        width: 100%;
    }

    .titleWithIcon {
    }

    .titleWithIcon img {
        width: 46px;
        height: 46px;
        margin-right: 16px;
    }

    .titleWithIcon h2 {
        line-height: 28px;
        font-size: 20px;
    }

    .paragraph {
        line-height: 24px;
        font-size: 15px;
        color: #002a59;
        margin-bottom: 40px;
    }

    .miniTitle {
        line-height: auto;
        font-size: 24px;
        font-weight: 700;
        color: #004DA6;
    }

    .paragraphBold {
        line-height: 24px;
        font-size: 15px;
        color: #002a59;
        margin-bottom: 40px;
        font-weight: bold;
    }

    .checkmarksContainer {
        margin-bottom: 16px;
    }

    .checkmarksContainer h2 {
        font-size: 20px;
        text-align: left;
        line-height: 20px;
    }

    .checkmarksContainer img {
        width: 20px;
        height: 20px;
        margin-top: 0px;
    }

    button {
        margin: auto;
    }


    .serviceContent {
    }

    .serviceTitleWithImage {
        display: flex;
        align-items:center;
    }

    .serviceTitleWithImage img {
        width: 48px;
        height: 48px;
        margin-top:0px;
    }

    .serviceTitleWithImage h2 {
        font-size: 20px;
        line-height:28px;
    }

    .serviceContent p {
        margin-top: 16px;
    }
}