@import "src/variable.module.scss";

.contentContainer {
    padding-left: 64px;
    padding-right: 64px;
}

.porscheImage {
    position:absolute;
    left: 0px;
    top:-50px;
    width: 45%;
}

.checkList {
    display: flex;
    align-items: center;
    margin-bottom:15px
}

.checkList img{
    width:30px;
    height:30px;
    margin-right:15px;
}

.checkList h4{

}
.pageTitle {
    margin-top: 68px;
}
.content {
    padding-top: 64px;
}
.porscheLogo{
    margin-bottom:24px;
}
@media(max-width: $desktop-min-width) {
    .porscheImage {
        position: relative;
        width: 100%;
        left: -7%;
    }

    .pageTitle {
        margin-top: 42px;
        font-size: 26px;
        line-height: 34px;
    }

    .contentContainer {
        padding-left: 24px;
        padding-right: 24px;
    }

    .content {
        padding-top: 38px;
    }

    .checkList img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }

    .checkList h4 {
        font-size: 15px;
        line-height: 22px;
    }

    .porscheLogo {
        width:100%;
    }

}