$primary_bg:#E6EAF1;
$text_color: #002A59;

$primary_bg_hover:#BECADD;

$secondary_bg: #004899;
$secondary_text: #E3F2FC;

$secondary_bg_hover: #00397A;

$tertiary_bg: #002A59;
$tertiary_text: #BECADD;

$quaternary_bg: #002A59;
$quaternary_text: #BECADD;

$quaternary_bg_hover: #001D3D;

$divider_color: #D9D9D9;


$tablet-min-width: 768px;
$desktop-min-width: 991px;